<template>
    <modal ref="modalCheckMaterials" titulo="Check de materiales" tamano="modal-xl">
        <div class="row mx-0 my-3 f-14">
            <p class="col-12"> Cantidad de materiales: <span class="f-15 f-600">{{ datosExcel ? datosExcel.length : 0 }}</span>  </p>
        </div>
        <div class="container-fluid">
            <div class="row  mx-0 f-14 text-general f-600 border-top border-right border-left br-t-4 border-bottom-g py-2">
                <div class="col-2">Ref.</div>
                <div class="col-3">Nombre</div>
                <div class="col-1">Tipo</div>
                <div class="col-1">U.M.</div>
                <div class="col-2">Últ. Valor Cotizado</div>
                <div class="col-1">Unidades</div>
                <div class="col-2">Plan</div>
            </div>
            <div class="materials-list overflow-auto custom-scroll">
                <div v-for="(data,idx) in datosExcel" :key="idx" class="row material position-relative mx-0 f-12  border-bottom border-left border-right py-3">
                    <div v-if="validarError(idx)" class="d-middle-center f-600 f-16 w-100 position-absolute text-white wrong">
                        <el-tooltip>
                            <div slot="content">
                                <p v-for="(err, index312) in errorsGroup[idx]" :key="index312">
                                   -> {{ err.error }}
                                </p>
                            </div>
                            <i v-if="errorsGroup[idx].length > 3" class="icon-alert f-20"></i>
                        </el-tooltip>
                        <p v-if="errorsGroup[idx].length <= 3">
                            La información no corresponde 
                            <span v-for="(err, index312) in errorsGroup[idx]" :key="index312" class="mx-2">"{{ err.campo }}"</span>
                        </p>
                    </div>
                    <div class="col-2">{{ data.referencia }}</div>
                    <div class="col-3">
                        <p class="tres-puntos">{{ data.nombre }}</p>
                    </div>
                    <div class="col-1">{{ data.tipo }}</div>
                    <div class="col-1">{{ data.unidad_medida }}</div>
                    <div class="col-2 text-center">{{ data.referencia }}</div>
                    <div class="col-1 text-center">{{ data.unidades }}</div>
                    <div class="col-2">
                        <p class="tres-puntos">{{ data.plan_mantenimiento }}</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <!-- <pagination :pagination="pagination" /> -->
                </div>
            </div>
        </div>
        <template v-if="error_subiendo" slot="end-buttons">
            <button v-if="error_subiendo.error" type="button" class="btn ml-1 mr-2 f-12 btn-general f-300" @click="uploadAgain">Volver a cargar</button>
            <button v-else type="button" class="btn ml-1 mr-2 f-12 btn-general f-300" @click="addMaterialsUploaded">Agregar</button>
        </template>

    </modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    data(){
        return {
            obj: {
                id_etapa: null,
                id_configuration: null,
                id: null,
            },
            uploadedFileName: '',
            filesToUpload:[],
            errorsGroup: {}
        }
    },
    computed: {
         ...mapGetters({
            errors_excel: 'almacen/stages/errors_excel',
            datosExcel: 'almacen/stages/datosExcel',
            error_subiendo: 'almacen/stages/error_subiendo',
        }),
    },
    // watch:{
    //     filesToUpload:{
    //         handler(val){
    //             this.$emit('input', val)
    //         },
    //         deep: true
    //     },
    // },
    methods: {
        toggle(){
            this.$refs.modalCheckMaterials.toggle()
            this.groupByFila()
        },
        uploadAgain(){
            this.$refs.modalCheckMaterials.toggle()
            this.$emit('uploadFileAgain')
        },
        addMaterialsUploaded(){
            this.$refs.modalCheckMaterials.toggle()
            this.$emit('reload')
        },
        groupByFila(){
            this.errorsGroup = _.groupBy(this.errors_excel, 'fila')
        },
        validarError(index){
            if (Object.keys(this.errorsGroup).includes(String(index))) return true
            return false
        }
    }
}
</script>

<style lang="scss" scoped>
.materials-list{
    max-height: 400px;
    .material{
        .wrong{
            background: #F7ABAB;
            filter: blur(1px);
            height: 100%;
            top: 0px;
            transition: .3s;
            z-index: 0;
        }
        &:hover{
            .wrong{
                filter: blur(0px);
                z-index: 1;
                transition: .5s;
            }
        }
    }
}
</style>
