<template>
    <modal ref="modalDelete" titulo="Eliminar material" adicional="Eliminar" @adicional="deleteMaterial">
        <div class="row mx-0 my-3 justify-content-center f-14">
            <div class="col text-center">
                <p> ¿Está seguro que quiere eliminar este material de lista? </p>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    data(){
        return {
            obj: {
                id_etapa: null,
                id_configuration: null,
                id: null,
            }
        }
    },
    methods: {
        ...mapActions({
            cantidad_materiales: 'almacen/stages/Action_get_cantidad_materiales'
        }),
        toggle(data){
            this.obj.id_etapa = data.id_etapa
            this.obj.id_configuration = data.id_configuration
            this.obj.id = data.id
            this.$refs.modalDelete.toggle()
        },
        async deleteMaterial(){
            await this.$store.dispatch('almacen/stages/Action_delete_material_config', this.obj)
            await this.$store.dispatch('almacen/stages/Action_get_info_table', { id_etapa:this.obj.id_etapa, id_configuration :  this.obj.id_configuration , filter: "GTFTF7845"});
            this.cantidad_materiales({ id_etapa: this.obj.id_etapa, id_configuration: this.obj.id_configuration })
            this.$refs.modalDelete.toggle()
            this.$emit('listarConfiguracion')
        }
    }
}
</script>

<style lang="css" scoped>
</style>
