<template>
    <section class="almacen-etapas-ver-config mt-2 ml-2">
        <navbar-admin titulo="Alamacén > Etapa 1 > Config 1">
            <el-popover v-model="visibleEditarConfig" placement="bottom-end" width="360" trigger="click" class="px-0" :disabled="!permitAction('almacen.etapa.editar.configuracion')">
                <div class="row mx-0 justify-content-center f-600 text-general">
                    Editar configuración
                </div>
                <hr />
                <ValidationObserver ref="validatorEditConfig">
                    <div class="row mx-0 justify-content-center my-2">
                        <div class="col-10 my-2">
                            <ValidationProvider v-slot="{ errors }" name="nombre" rules="required">
                                <p class="input-label-top">Nombre de la configuración</p>
                                <el-input v-model="modelEditConfiguration.nombre" class="f-12 br-5 w-100" size="small" />
                                <vee-error :text="errors[0]"/>
                            </ValidationProvider>
                        </div>
                        <div class="col-10 my-2">
                            <ValidationProvider v-slot="{ errors }" name="horas" rules="required|min_value:0">
                                <p class="input-label-top">Horas / Hombre</p>
                                <el-input v-model="modelEditConfiguration.horas_hombre" class="f-12 br-5 w-100" size="small" />
                                <vee-error :text="errors[0]"/>
                            </ValidationProvider>
                        </div>
                        <div class="col-10 my-2">
                            <ValidationProvider v-slot="{ errors }" name="utilidad" rules="required|min_value:0|max_value:100">
                                <p class="input-label-top">Porcentaje de utilidad</p>
                                <el-input v-model="modelEditConfiguration.porcentaje" class="f-12 br-5 w-100" size="small" />
                                <vee-error :text="errors[0]"/>
                            </ValidationProvider>
                        </div>
                    </div>
                </ValidationObserver>
                <hr />
                <div class="text-right m-0">
                    <button class="btn btn-cerrar f-12" @click="visibleEditarConfig = !visibleEditarConfig">Cerrar</button>
                    <button class="btn btn-crear f-12 ml-2" @click="editConfig">Guardar</button>
                </div>
                <el-tooltip slot="reference" content="Editar" placement="bottom-end" effect="light" visible-arrow>
                    <button class="btn btn-square32-5d ml-2"
                        :class="permitAction('almacen.etapa.editar.configuracion') ? 'cr-pointer' : 'cr-not-allowed'" 
                        @click="functionPermitAction('almacen.etapa.editar.configuracion')"
                    >
                        <i class="icon-pencil-outline text-white" />
                    </button>
                </el-tooltip>
            </el-popover>
            <el-tooltip content="Eliminar" placement="bottom-end" effect="light" visible-arrow>
                <button class="btn btn-square32-5d ml-2"
                    :class="permitAction('almacen.etapa.eliminar.configuracion') ? 'cr-pointer' : 'cr-not-allowed'" 
                    @click="functionPermitAction('almacen.etapa.eliminar.configuracion', eliminarEtapa)"
                >
                    <i class="icon-trash-can-outline text-white" />
                </button>
            </el-tooltip>
        </navbar-admin>
        <!-- nombre -->
        <div class="row">
            <div class="col-12 col-lg-9 mb-3 mb-lg-0">
                <div class="bg-f9 border-dd br-4 px-3 h-52px d-middle">
                    <div class="row mx-0 w-100">
                        <div class="col my-auto">
                            <p class="f-600 ucfirst"> {{ configurationData.nombre }} </p>
                        </div>
                        <div class="col-auto my-auto ml-auto pr-0">
                            <div class="d-flex">
                                <p class="my-auto f-13"> Porcentaje de utilidad <span>{{ configurationData.porcentaje }}</span> % </p>
                            </div>
                        </div>
                        <div class="col-auto my-auto pr-0">
                            <div class="d-flex">
                                <el-tooltip content="Cantidad y valor de los materiales" placement="bottom" effect="light" visible-arrow>
                                    <i class="icon-format-list-numbered f-18 mr-1" />
                                </el-tooltip>
                                <p class="my-auto f-13"> {{ configurationData.materiales }} - {{ formatoMoneda(configurationData.valor_material) }} </p>
                            </div>
                        </div>
                        <div class="col-auto my-auto ml-auto pr-0">
                            <div class="d-flex">
                                <i class="icon-account-clock f-18 mr-1" />
                                <p class="my-auto f-13"> {{ configurationData.horas_hombre }} Hrs. - {{ formatoMoneda(configurationData.valor_hora_hombre) }} </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col col-lg-3">
                <div class="bg-f9 border br-4 px-3 d-middle-center h-52px">
                    <p class="f-600">{{ formatoMoneda(configurationData.valor_horas + configurationData.valor_material) }} </p>
                </div>
            </div>
        </div>
        <!-- add material -->
        <div class="row mb-3 mt-4">
            <!-- add material btn -->
            <div class="col-auto ml-auto icon-option my-auto px-1">
                <el-popover v-model="visibleAgregarMaterial" placement="bottom-end" width="360" trigger="click" class="px-0" :disabled="!permitAction('almacen.etapa.agregar.material.configuracion')">
                    <div class="row mx-0 justify-content-center f-600 text-general">
                        Añadir material
                    </div>
                    <hr />
                    <div class="row">
                        <ValidationObserver ref="validatorAddMaterial" tag="div" class="row mx-0">
                            <ValidationProvider v-slot="{ errors }" name="nombre" rules="required" tag="div" class="col-12 position-relative mb-2">
                                <p class="input-label-top">Nombre del material</p>
                                <el-select v-model="modelAddMaterial.idMaterial" filterable placeholder="Seleccionar material" size="small" class="w-100">
                                    <el-option v-for="item in materialsSelect" :key="item.id" :label="item.nombre" :value="item.id"
                                    :disabled="contarMateriales.includes(item.id)" />
                                </el-select>
                                <vee-error :text="errors[0]"/>
                            </ValidationProvider>
                            <ValidationProvider v-slot="{ errors }" name="unidades" rules="required" tag="div" class="col-12 position-relative mb-2">
                                <p class="input-label-top">Unidades</p>
                                <el-input v-model="modelAddMaterial.cantUnidades" placeholder="Cant unidades" size="small" class="w-100" />
                                <vee-error :text="errors[0]"/>
                            </ValidationProvider>
                        </ValidationObserver>
                    </div>
                    <hr />
                    <div class="text-right m-0">
                        <button class="btn btn-cerrar f-12" @click="visibleAgregarMaterial = !visibleAgregarMaterial">Cerrar</button>
                        <button class="btn btn-crear f-12 ml-2" @click="addMaterialConfiguration">Guardar</button>
                    </div>
                    <el-tooltip slot="reference" content="Añadir material" placement="bottom" effect="light" visible-arrow>
                        <i class="icon-plus-circle f-26" 
                            :class="permitAction('almacen.etapa.agregar.material.configuracion') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click="functionPermitAction('almacen.etapa.agregar.material.configuracion')"
                        />
                    </el-tooltip>
                </el-popover>
            </div>

            <!-- importar excel -->
            <div class="d-none">
                <div class="col-auto icon-option my-auto px-1">
                    <el-tooltip content="Importar excel con lista de materiales" placement="bottom" effect="light" visible-arrow>
                        <i class="icon-upload-outline f-26 cr-pointer"
                            :class="permitAction('almacen.etapa.importar.materiales.configuracion') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click="functionPermitAction('almacen.etapa.importar.materiales.configuracion', openModalImportMaterials)"
                        />
                    </el-tooltip>
                </div>
                <!-- descargar  -->
                <div class="col-auto icon-option my-auto px-1" style="transform:rotate(180deg) !important">
                    <el-tooltip content="Descargar plantilla" placement="bottom" effect="light" visible-arrow
                        :class="permitAction('almacen.etapa.descargar.plantilla.configuracion') ? 'cr-pointer' : 'cr-not-allowed'" 
                        @click="functionPermitAction('almacen.etapa.descargar.plantilla.configuracion')"
                    >
                        <a v-if="permitAction('almacen.etapa.descargar.plantilla.configuracion')" href="https://v2-fullsmart-iaf.s3.amazonaws.com/iaf-software/plantillas/plantilla.xlsx" 
                        class="icon-upload-outline download_template f-26 cr-pointer" 
                        download="Plantilla materiales" 
                        />
                        <p v-else class="icon-upload-outline download_template f-26 cr-not-allowed"></p>
                    </el-tooltip>
                </div>
            </div>
            <!-- agretar material de otra config -->
            <div class="col-auto icon-option my-auto px-1">
                <el-popover v-model="visibleAgregarMaterialConf" placement="bottom-end" width="360" trigger="click" class="px-0" :disabled="!permitAction('almacen.etapa.agregar.materiales.configuracion')">
                    <div class="row mx-0 justify-content-center f-600 text-general">
                        Añadir materiales de una configuración
                    </div>
                    <hr />
                    <ValidationObserver ref="validatorAddMateriaOther">
                        <div class="row mx-0 justify-content-center my-2">
                            <div class="col-10 my-2">
                                <ValidationProvider v-slot="{ errors }" name="etapa" rules="required">
                                    <p class="input-label-top">Etapa</p>
                                    <el-select v-model="modelAddMaterialOther.id_stage" placeholder="Seleccionar etapa" size="small" class="w-100" @change="setConfigurationsSelect">
                                        <el-option v-for="item in stagesSelect" :key="item.id" :label="item.nombre" :value="item.id" />
                                    </el-select>
                                    <vee-error :text="errors[0]"/>
                                </ValidationProvider>
                            </div>
                            <div class="col-10 my-2">
                                <ValidationProvider v-slot="{ errors }" name="configuración" rules="required">
                                    <p class="input-label-top">Configuración</p>
                                    <el-select v-model="modelAddMaterialOther.id_configuration" placeholder="Seleccionar configuración" size="small" class="w-100">
                                        <el-option v-for="item in configurationOther" :key="item.id" :label="item.nombre" :value="item.id" />
                                    </el-select>
                                    <vee-error :text="errors[0]"/>
                                </ValidationProvider>
                            </div>
                        </div>
                    </ValidationObserver>
                    <hr />
                    <div class="text-right m-0">
                        <button class="btn btn-cerrar f-12" @click="visibleAgregarMaterialConf = !visibleAgregarMaterialConf">Cerrar</button>
                        <button class="btn btn-crear f-12 ml-2" @click="addMaterialConfigurationClone">Añadir</button>
                    </div>
                    <el-tooltip slot="reference" content="Añadir lista de materiales de otra configuración" placement="bottom" effect="light" visible-arrow>
                        <i class="icon-text-box-plus-outline f-26" 
                            :class="permitAction('almacen.etapa.agregar.materiales.configuracion') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click="functionPermitAction('almacen.etapa.agregar.materiales.configuracion')"
                        />
                    </el-tooltip>
                </el-popover>
            </div>
            <!-- buscar en tabla -->
            <div class="col-auto icon-option my-auto px-1">
                <input-search v-model="query" :search="beforeSearch" auto-search placeholder="Buscar" size="small" />
            </div>
            <!-- abrir filtros -->
            <div class="col-auto icon-option my-auto pl-0">
                <filter-indicator :count="filterCount" @openModalFilter="abrirModalFiltros" @clearFilter="reload"/>
            </div>
        </div>
        <tabla-general
        :usar-servidor="false" 
        :usar-paginacion="false"
        :mostrarBuscador="false" 
        altura-tabla="500px" 
        :data="tableMaterials">
            <el-table-column label="Ref" prop="ref" width="100">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos"> {{ scope.row.referencia }} </p>
                </template>
            </el-table-column>
            <el-table-column label="Nombre" prop="nombre" show-overflow-tooltip>
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos"> {{ scope.row.nombre }} </p>
                </template>
            </el-table-column>
            <el-table-column label="Tipo" prop="tipo" width="100">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos"> {{ scope.row.tipo }} </p>
                </template>
            </el-table-column>
            <el-table-column label="U.M." prop="um" width="80">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos"> {{ scope.row.unidad_medida }} </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor cotizado" prop="valorCotizado">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">{{ formatoMoneda(scope.row.valor_cotizado) }} </p>
                </template>
            </el-table-column>
            <el-table-column label="Unidades" prop="unidades" width="120">
                <template slot-scope="scope">
                    <el-input
                    v-model="scope.row.unidades"
                    size="small"
                    @change="editMaterialConfig(scope.row.idMaterialConfig, scope.row.unidades, scope.row.id, scope.row.mantenimiento ? scope.row.mantenimiento.id : null)"
                    />
                </template>
            </el-table-column>
            <el-table-column label="Plan de mantenimiento" prop="planMantenimiento" show-overflow-tooltip>
                <template slot-scope="scope">
                    <!-- <Selecter
                    :data="scope.row.mantenimientos"
                    :model="scope.row.mantenimiento"
                    prop="id"
                    @selected="editMaterialConfigMantenimiento(scope.row.idMaterialConfig, scope.row.unidades, scope.row.id, $event)"
                    /> -->
                    <template v-if="vmodels && vmodels[scope.$index]">
                        <el-select v-model="vmodels[scope.$index]" placeholder="Select" size="small" @change="editMaterialConfigMantenimiento($event, scope.row.idMaterialConfig, scope.row.unidades, scope.row.id)">
                            <el-option v-for="item in scope.row.mantenimientos" :key="item.id" :label="item.nombre" :value="item.id" />
                        </el-select>
                    </template>
                    <template v-else>
                        <el-select placeholder="Select" size="small" @change="editMaterialConfigMantenimiento($event, scope.row.idMaterialConfig, scope.row.unidades, scope.row.id)">
                            <el-option v-for="item in scope.row.mantenimientos" :key="item.id" :label="item.nombre" :value="item.id" />
                        </el-select>
                    </template>
                </template>
            </el-table-column>
            <el-table-column width="50">
                <template slot-scope="scope">
                    <el-tooltip content="Eliminar" placement="bottom" effect="light" visible-arrow>
                        <i class="icon-trash-can-outline f-20" 
                            :class="permitAction('almacen.etapa.eliminar.materiales.configuracion') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click="functionPermitAction('almacen.etapa.eliminar.materiales.configuracion', tryDeleteMaterial, [scope.row.idMaterialConfig])"
                        />
                    </el-tooltip>
                </template>
            </el-table-column>
        </tabla-general>
        <div class="row justify-content-center">
            <div class="col-auto">
                <Pagination :pagination="pagination" layout="prev, pager, next" @paginate="getInfoTable({ id_etapa: idStage, id_configuration: idConfiguration })"/>
            </div>
        </div>
        <!-- partials  -->
        <modal-eliminar ref="abrirModalEliminarConfiguracion" title="Eliminar configuración" mensaje="¿Está seguro que quiere eliminar esta configuración?" mensaje2="Se eliminará de cualquier equipo modelo que lo tenga en su sistema" @delete="deleteConfiguration" />
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-11 my-4">
                    <p class="input-label-top">Tipo de material</p>
                    <el-select v-model="filter.id_tipo_material" clearable placeholder="Seleccionar material" size="small" class="w-100">
                        <el-option v-for="item in materialTypes" :key="item.id" :label="item.tipo_material" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-11 my-4">
                    <p class="input-label-top">Unidad de medida</p>
                    <el-select v-model="filter.id_unidad_medida" placeholder="Seleccionar U.M" size="small" class="w-100">
                        <el-option v-for="item in select_measure_units" :key="item.id" :label="item.unidad_medida" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-11 my-4">
                    <p class="input-label-top">Rango de valor</p>
                    <el-slider v-model="rangeValorCotizado" range :min="Number(rangeValueFilter.valor_minimo)" :max="Number(rangeValueFilter.valor_maximo)" :format-tooltip="formatTooltipRange"/>
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="reload"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filterMaterialsList"> Filtrar </button>
            </div>
        </modal-lateral>
        <modal-eliminar-material-config ref="tryDeleteMaterial" @listarConfiguracion="setDataConfiguration"/>
        <modal-upload-file-materials ref="modalUploadFileMaterials" @checkMaterials="openModalCheckMaterials" />
        <!-- <modal-check-file-materials ref="modalCheckFileMaterials" @uploadFileAgain="openModalImportMaterials" @reload="reload" /> -->
        <modal-message-info ref="openModalMessageInfo" />
    </section>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { getRange } from '~/services/global'
import modalEliminarMaterialConfig from './partials/modalEliminarMaterialConfig.vue'
import modalUploadFileMaterials from './partials/modalUploadFileMaterials.vue'
import modalCheckFileMaterials from './partials/modalCheckFileMaterials.vue'
import modalMessageInfo from './partials/modalMessageInfo.vue'
export default {
    components:{
        modalEliminarMaterialConfig,
        modalUploadFileMaterials,
        modalCheckFileMaterials,
        modalMessageInfo,
    },
    data(){
        return{
            visibleEditarEtapa: false,
            visibleEditarConfig: false,
            visibleAgregarMaterial: false,
            visibleAgregarMaterialConf: false,
            visible: false,
            inputNombreMaterial: '',
            inputCantUnidades: '',
            inputNombreConfig: '',
            inputBuscarMateriales: '',

            idStage: '',
            idConfiguration: '',
            configurationData: {},
            modelEditConfiguration: {
                nombre: '',
                horas_hombre: '',
                porcentaje: ''
            },
            modelAddMaterial:{
                idMaterial: '',
                cantUnidades: '',
            },
            stagesOther: [],
            configurationOther: [],
            modelAddMaterialOther:{
                id_stage: '',
                id_configuration: '',
            },
            mantenimientos:[
                {
                    id: 1,
                    nombre: 'mantenimiento 1',
                },
                {
                    id: 2,
                    nombre: 'mantenimiento 2',
                },
                {
                    id: 3,
                    nombre: 'mantenimiento 3',
                },
            ],
            selectMantenimiento: '',
            //filter
            valueSelectTypes: '',
            vmodels: []
        }
    },
    async created(){
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', ['almacen.main', 'almacen.etapas']);
        // getting ids
        this.idConfiguration = this.$route.params.id_configuracion
        this.idStage = this.$route.params.id
        // get data configuration
        this.setDataConfiguration()
        // get materials for the select
        this.setMaterialsSelect()
        // get stages for the select
        this.setStagesSelect()
        // get select measure units
        this.getSelects(['Action_get_select_measure_units'])
        // get range value config
        await this.reload()
        // set value range 
        // this.range = getRange(this.tableMaterials, 'valor_cotizado')
        this.$store.dispatch('navigation/breadcumbs/spliceBreadcumbs',[
            {
                position: 2,
                breadcumb: {
                    name: this.stagesSelect.find(item => item.id == this.idStage)?.nombre,
                    route: 'almacen.etapas.ver',
                    params: { id: this.idStage }
                }
            },
            {
                position: 3,
                breadcumb: {
                    name: this.configurationData.nombre,
                    route: 'almacen.etapas.ver.configuracion',
                    params: { id: this.idStage, id_configuracion: this.idConfiguration }
                }
            }
        ])
    },
    watch: {
        tableMaterials() {
            this.vmodels = []
            this.tableMaterials.forEach(el => {
                this.vmodels.push(el.mantenimiento?.id ?? '')
            })
        }
    },
    computed:{
        ...mapGetters({
			user: 'auth/user',
            materialsSelect: 'almacen/stages/materialsSelect',
            stagesSelect: 'almacen/stages/stagesSelect',
            configurationsSelect: 'almacen/stages/configurationsSelect',
            materialTypes: 'configurar/almacen/tipos_material/materialTypes',
            tableMaterials: 'almacen/stages/tableMaterials',
            select_measure_units: 'selects/selects/select_measure_units',
            filters: 'almacen/stages/filter',
            contarMateriales: 'almacen/stages/contarMateriales',
            rangeValueFilter: 'almacen/stages/rangeValueFilter',
        }),
        // filters
        filter:{
            get(){ return this.filters },
            set(val){ this.set_filter(val) }
        },
        pagination: {
            get(){
                return this.$store.getters['almacen/stages/pagination']
            }, 
            set(val){
                this.$store.commit('almacen/stages/set_pagination', val)
            }
        },
        query: {
            get(){
                return this.$store.getters['almacen/stages/query']
            }, 
            set(val){
                this.$store.commit('almacen/stages/set_query', val)
            }
        },
        rangeValorCotizado:{
            get(){
                return [this.filter.valor_minimo, this.filter.valor_maximo]
            },
            set(val){
                this.filter.valor_minimo = val[0]
                this.filter.valor_maximo = val[1]
            }
        },
        disableSlider(){
            return this.rangeValueFilter.valor_maximo == this.rangeValueFilter?.valor_minimo 
        },
        filterCount(){
            return this.filter.count()
        }
    },
    async mounted(){
        await this.getMaterialTypes()
        this.cantidad_materiales({ id_etapa: this.idStage, id_configuration: this.idConfiguration })
    },
    methods:{
        ...mapActions({
            //clearFilter: 'almacen/stages/clear_filter',
            getMaterialTypes: 'configurar/almacen/tipos_material/Action_list_material_types',
            getInfoTable: 'almacen/stages/Action_get_info_table',
            getSelects: 'selects/selects/sync',
            Action_get_value_range: 'almacen/stages/Action_get_value_range',
            clear_filter_config: 'almacen/stages/clear_filter_config',
            cantidad_materiales: 'almacen/stages/Action_get_cantidad_materiales'
        }),
        ...mapMutations({
            set_filter: 'almacen/stages/set_filter',
        }),
        async initTable(){
            await this.getInfoTable({ id_etapa: this.idStage, id_configuration: this.idConfiguration });
        },
        async beforeSearch(){
            this.pagination.current_page = 1;
            await this.getInfoTable({ id_etapa: this.idStage, id_configuration: this.idConfiguration })
        },
        openModalCheckMaterials(){
            this.$refs.openModalMessageInfo.toggle()

            setTimeout(() => {
                this.$refs.openModalMessageInfo.toggle()
                this.$refs.modalCheckFileMaterials.toggle()
            }, 1200);

        },
        // openModalImportMaterials(){
        //     this.$refs.modalUploadFileMaterials.toggle()
        // },
        verConfiguracion(){
            this.$router.push({ name: '' })

        },
        guardarNuevoNombre(){
            this.visibleEditarEtapa = !this.visibleEditarEtapa
        },
        eliminarEtapa(){
            this.$refs.abrirModalEliminarConfiguracion.toggle()
        },
        guardarNuevoMaterialConfig(){
            this.visibleAgregarMaterialConf = !this.visibleAgregarMaterialConf
        },
        eliminarMaterial(){
            this.$refs.abrirModalEliminarMaterial.toggle()
        },
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        formatTooltipRange(val){
            return this.formatoMoneda(val)
        },
        async setDataConfiguration(){
            let obj = {
                id_etapa: this.idStage,
                id: this.idConfiguration,
            }
            await this.$store.dispatch('almacen/stages/Action_get_configuration', obj);
            const data = this.$store.getters['almacen/stages/stagesConfiguration']
            this.configurationData = data
            this.modelEditConfiguration = {
                nombre: this.configurationData.nombre,
                horas_hombre: this.configurationData.horas_hombre,
                porcentaje: this.configurationData.porcentaje,
            }
        },
        async editConfig(){
            let valid = await this.$refs.validatorEditConfig.validate();
            if (valid) {
                let obj = {
                    id_etapa: this.idStage,
                    id: this.idConfiguration,
                    nombre: this.modelEditConfiguration.nombre,
                    horas: this.modelEditConfiguration.horas_hombre,
                    porcentaje: this.modelEditConfiguration.porcentaje,
                }
                await this.$store.dispatch('almacen/stages/Action_update_configuration', obj )
                this.setDataConfiguration()
                this.visibleEditarConfig = !this.visibleEditarConfig
            }
        },
        async deleteConfiguration(){
            await this.$store.dispatch('almacen/stages/Action_delete_configuration', { id_etapa: this.idStage, id: this.idConfiguration})
            this.$router.push({ name: 'almacen.etapas.ver', params: { id: this.idStage} })
        },
        // Materials configuration
        async setMaterialsSelect(){
            await  this.$store.dispatch('almacen/stages/Action_get_materials_configuration_select', this.idStage, this.idConfiguration)
        },
        async addMaterialConfiguration(){
            let valid = await this.$refs.validatorAddMaterial.validate();
            if (valid) {
                let obj = {
                    id_etapa: this.idStage,
                    id_configuration: this.idConfiguration,
                    id_material: this.modelAddMaterial.idMaterial,
                    unidades: this.modelAddMaterial.cantUnidades,
                }
                await this.$store.dispatch('almacen/stages/Action_add_material_configuration', obj )
                this.modelAddMaterial = {
                    idMaterial: '',
                    cantUnidades: '',
                }
                this.$refs.validatorAddMaterial.reset();
                this.visibleAgregarMaterial = !this.visibleAgregarMaterial
                await this.reload()
            }
        },
        // add material from other configuration
        async addMaterialConfigurationClone(){
            let valid = await this.$refs.validatorAddMateriaOther.validate();
            if (valid) {
                let obj = {
                    id_etapa: this.idStage,
                    id_configuracion_etapa: this.idConfiguration,
                    id_configuracion: this.modelAddMaterialOther.id_configuration
                }
                await this.$store.dispatch('almacen/stages/Action_add_material_configuration_clone', obj )
                this.modelAddMaterialOther = {
                    id_stage: '',
                    id_configuration: '',
                }
                this.visibleAgregarMaterialConf = !this.visibleAgregarMaterialConf
                this.$refs.validatorAddMateriaOther.reset();
                await this.reload()
            }
        },
        async setStagesSelect(){
            await  this.$store.dispatch('almacen/stages/Action_get_stages_select', this.idStage, this.idConfiguration)
        },
        async setConfigurationsSelect(){
            let obj = {
                id_stage: this.idStage,
                idConfiguration: this.idConfiguration,
                id_etapa: this.modelAddMaterialOther.id_stage
            }
            await  this.$store.dispatch('almacen/stages/Action_get_configurations_select', obj)
            this.configurationOther = this.configurationsSelect
        },
        // table materials data
        async editMaterialConfig(id, unidades, id_material, id_mantenimiento){
            let obj = {
                id_etapa: this.idStage,
                id_configuracion: this.idConfiguration,
                id: id,
                id_material: id_material,
                unidades: unidades,
                id_mantenimiento: id_mantenimiento,
            }
            await this.$store.dispatch('almacen/stages/Action_update_material_config', obj )
            await this.reload()
            this.setDataConfiguration()
        },
        // update plan de mantenimiento
        async editMaterialConfigMantenimiento(id_mantenimiento, id, unidades, id_material){
            let obj = {
                id_etapa: this.idStage,
                id_configuracion: this.idConfiguration,
                id: id,
                id_material: id_material,
                unidades: unidades,
                id_mantenimiento: id_mantenimiento,
            }
            await this.$store.dispatch('almacen/stages/Action_update_material_config', obj )
            await this.reload()
        },
        async tryDeleteMaterial(id){
            let obj = {
                id: id,
                id_etapa: this.idStage,
                id_configuration: this.idConfiguration,
            }
            await this.$refs.tryDeleteMaterial.toggle(obj)
        },
        // filters
        async filterMaterialsList(){
            await this.initTable();
            this.$refs.abrirModalFiltros.toggle();
        },
        async reload(){
            await this.cantidad_materiales({ id_etapa: this.idStage, id_configuration: this.idConfiguration })
            await this.setDataConfiguration()
            await this.Action_get_value_range({id_configuracion: this.idConfiguration, id_etapas: this.idStage})
            await this.clear_filter_config()
            this.initTable()
        }
    },
    beforeDestroy(){
        this.query = ''
    }
}
</script>
<style lang="scss" scoped>
.almacen-etapas-ver-config{
    .h-52px{
        height: 53px;
    }
    .icon-option{
        .icon-plus-circle, .icon-text-box-plus-outline, .icon-upload-outline, .icon-trash-can-outline{
            &:hover{
                color: var(--color-general);
            }
        }
    }
    .icon-trash-can-outline{
        &:hover{
            color: var(--color-general);
        }
    }
    .input-label-top{
        position: absolute;
        top: -15px;
    }
    .download_template{
        color: var(--color-5d);
        &:hover{
            text-decoration: none !important;
        }
    }
}
</style>