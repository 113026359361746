<template>
    <modal ref="modalImportMaterials" titulo="Importar materiales">
        <div class="row mx-0 my-3 justify-content-center f-14">
            <div class="col-auto text-center">
                <input ref="uploaderInput" class="d-none" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" name="fileUploader" @change="onChange" />
                <i class="icon-monitor-arrow-down-variant cr-pointer f-60 text-5d br-4 bg-f5 border shadow29" @click="$refs.uploaderInput.click()" />
                <p class="f-12">Examinar equipo</p>
            </div>
            <div v-if="uploadedFileName" class="col my-auto">
                <p class="f-13"> Archivo </p>
                <p class="border p-2 f-12 br-5 mt-1">
                    {{ uploadedFileName ? uploadedFileName : '' }}
                </p>
            </div>
        </div>
        <template slot="end-buttons">
            <button v-if="uploadedFileName" type="button" class="btn ml-1 mr-2 f-12 btn-general f-300" @click="agregarMateriales">Agregar</button>
        </template>
    </modal>
</template>

<script>
var XLSX = require("xlsx");
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    data(){
        return {
            obj: {
                id_etapa: null,
                id_configuration: null,
                id: null,
            },
            uploadedFileName: '',
            filesToUpload:[],
            fileeee: '',
            finalDataExcel: null,
            idConfiguration: '',
            idStage: '',
        }
    },
    watch:{
        filesToUpload:{
            handler(val){
                this.$emit('input', val)
            },
            deep: true
        },
    },
    created(){
        this.idConfiguration = this.$route.params.id_configuracion
        this.idStage = this.$route.params.id
    },
    computed: {
         ...mapGetters({
            error_subiendo: 'almacen/stages/error_subiendo',
        }),
    },
    methods: {
        ...mapMutations({
            prueba_excel: 'almacen/stages/prueba_excel',
        }),
        ...mapActions({
            Action_validate_excel: 'almacen/stages/Action_validate_excel',
        }),
        toggle(){
            this.$refs.modalImportMaterials.toggle()
        },
        async agregarMateriales(){
            let obj = {
                id_etapa: this.idStage,
                id_configuracion: this.idConfiguration,
                data: this.finalData
            }
            await this.Action_validate_excel(obj)
            this.$refs.modalImportMaterials.toggle()
            this.$emit('checkMaterials')
            this.$refs.uploaderInput.value = ''
            this.filesToUpload = []
            this.uploadedFileName = ''
            this.fileeee = ''
        },
        setFiles(e){
            let fileArray = Array.from(e.target.files)
            console.log('fileArray',fileArray);
            this.filesToUpload = fileArray
            this.uploadedFileName = fileArray[0].name
            // fileArray.forEach(file => {
            //     this.uploadedFileName = file.name
            // })
        },
        onChange(event) {
            let fileArray = Array.from(event.target.files)
            this.filesToUpload = fileArray
            this.uploadedFileName = fileArray[0].name

            this.fileeee = event.target.files ? event.target.files[0] : null;
            if (this.fileeee) {
                const reader = new FileReader();

                reader.onload = (e) => {
                    /* Parse data */
                    const bstr = e.target.result;
                    const wb = XLSX.read(bstr, { type: 'binary' });
                    /* Get first worksheet */
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    /* Convert array of arrays */
                    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                    let finalData = data.shift()
                    this.prueba_excel(data)
                    this.transformData(data)
                }

                reader.readAsBinaryString(this.fileeee);
            }
        },
        transformData(data){
            let lastOne = []
            let payload = {}
            data.forEach(element => {
                payload = {
                    referencia: element[0],
                    nombre: element[1],
                    tipo: element[2],
                    unidad_medida: element[3],
                    valor_cotizado: element[4],
                    unidades: element[5],
                    plan_mantenimiento: element[6],
                    
                }
                if (typeof payload.referencia != 'undefined' && typeof payload.nombre != 'undefined' && typeof payload.tipo != 'undefined' && typeof payload.unidad_medida != 'undefined' && typeof payload.valor_cotizado != 'undefined' && typeof payload.unidades != 'undefined' && typeof payload.plan_mantenimiento != 'undefined') {
                    lastOne.push(payload)
                }
            });
            this.finalData = lastOne
            this.prueba_excel(this.finalData)
        }
    }
}
</script>

<style lang="css" scoped>
</style>
